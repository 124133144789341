import Navmob from "./Nav mob";
import NewNav from "./NewNav";
const Navbar = (props) => {
  return (
    <>
      <Navmob title="home" />
      <NewNav title="home" />
    </>
  )
}
export default Navbar;
