import Logo from "../../assets/images/header/SIA Logo.png";
import Close from "../../assets/images/close.svg";
import Menu from "../../assets/images/menu.svg";
import homemail from "../../assets/images/header/mail1.png";
import homephone from "../../assets/images/header/phone1.png";
import UPSC from "../../assets/images/header/UPSC.png";
import TNPSC from "../../assets/images/header/TNPSC.png";
import Banking from "../../assets/images/header/banking.png";
import Styles from "./Navbar.module.css";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Modal from 'react-bootstrap/Modal';
import chennai from "../../assets/images/header/chn.png";
import delhi from "../../assets/images/header/del.png";
import bengaluru from "../../assets/images/header/ben.png";
import thiruvandram from "../../assets/images/header/tvn.png";
import hyderabad from "../../assets/images/header/hyd.png";

const Nav = (...props) => {

  const [active, setActive] = useState(props.title);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const announcement = [
    { link: "/upsc-admission-anna-nagar#paymentsection", cont: "UPSC CSE Coaching 2024-25 Pre Booking" },
    { link: "/two-year-upsc-program", cont: "Sadhana 2025 - Exclusive 2 year programme for UPSC Civil Service Exam" },
    { link: "/upsc-environment-book", cont: "UPSC Environment Book Buy Now" },
    { link: "/csat-plus", cont: "CSAT Plus Revision" }
  ]

  const announcements = [
    { link: "/upsc-prelims-test-series", cont: "UPSC Prelims Test Series 2024" },
    { link: "/upsc-interview", cont: "UPSC CSE 2023 FREE Interview Coaching" },
    { link: "/sia-all-india-prelims-mock-test", cont: "All India Prelims Mock Test 2024" }

  ]

  const announcement1 = [
    {link: "/tnpsc-group-i-prelims-test-series", contcolor:"redcolor",cont: "TNPSC Group I & II Prelims Test Series 2024"},
    { link: "/tnpsc-general-enrichment-programme", cont: "TNPSC-GMAT Enrichment Programme Group II & IV 2024" },
    { link: "/tnpsc-admissions#coursedetails", cont: "TNPSC Gr I / II Prelims Coaching" }
  ]
  const announcements1 = [
    { link: "/tnpsc-group-iv-test-batch", cont: "TNPSC Group IV Test Series 2024" },
    { link: "/tnpsc-group-iv-workshop", cont: "TNPSC Group IV Class-cum-Workshop 2023" } 
  ]

  const announcement2 = [
    { link: "https://www.shankarbankingacademy.com/ssc-banking-admission/", route: "_blank", cont: "Shankar Banking Academy - Banking Current Affairs | SSC Current affairs | Railway and Insurance Current affairs" }
  ]
  const announcements2 = [
    { link: "https://docs.google.com/forms/d/e/1FAIpQLSdBcEeSW1BApjoMctUBWl3zqId4upMyJIZcmj66ayyoP0_m6w/viewform?usp=sf_link", route: "_blank", cont: "IBPS SO Mock interview 2024" }
  ]

  return (
    <>

      <div className="container-fluid p-0 d-block d-lg-none sticky-top">
        <nav className={`${Styles.navstyle} navbar flex-nowrap`}>
          <div className="container">
            <Link href="/">
              <Image loading="eager" placeholder="blur" src={Logo} className={`${Styles.logoimg} img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
            </Link>
            <div className="">
              <Image
                src={Menu}
                alt="hamburger icon"
                title="Hamburger"
                className="cursorPointer"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              />
              <div
                className={`${Styles.border} offcanvas offcanvas-start`}
                tabIndex={-1}
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasExampleLabel"
                  ></h5>

                  <Image
                    src={Close}
                    alt="close icon"
                    title="Close"
                    className="cursorPointer"
                    aria-label="Close"
                    data-bs-dismiss="offcanvas"
                  />
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav">

                    <div className="">
                      <div className="pb-2"><button type="button" onClick={() => setModalShow(true)} className={`${Styles.announcebtn}`}>What's New</button></div>
                      {/* <Link onClick={() => setModalShow(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-3`} href="">UPSC</Link>
                      <Link onClick={() => setModalShow1(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-3`} href="">TNPSC</Link>
                      <Link onClick={() => setModalShow2(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-3`} href="">Banking</Link> */}
                      {/* <Link className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`} href=""></Link> */}
                    </div>

                    <div className="row">
                      <div className="col pt-md-0 pt-3 text-center">
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-admission-anna-nagar">
                            <Image src={chennai} alt="Chennai_logo" title="Shankar IAS Academy" />
                            <button type="button" className="newnav" >Chennai</button></Link>
                        </span>
                      </div>

                      <div className="col pt-md-0 pt-3 text-center">
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/delhi">
                            <Image src={delhi} alt="Delhi_logo" title="Shankar IAS Academy" />
                            <button type="button" className={`${Styles.delhibtn} newnav text-white`} >Delhi</button></Link>
                        </span>
                      </div>
                      <div className="col pt-md-0 pt-3 text-center">
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/bengaluru">
                            <Image src={bengaluru} alt="Bengaluru_logo" title="Shankar IAS Academy" />
                            <button type="button" className="newnav" >Bengaluru</button></Link>
                        </span>
                      </div>
                      <div className="col py-md-0 py-3 text-center">
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/thiruvananthapuram">
                            <Image src={thiruvandram} alt="Thiruvandram_logo" title="Shankar IAS Academy" />
                            <button type="button" className="newnav" >Thiruvananthapuram</button></Link>
                        </span>
                      </div>
                      <div className="col py-md-0 py-3 text-center">
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/hyderabad">
                            <Image src={hyderabad} alt="Hyderabad_logo" title="Shankar IAS Academy" />
                            <button type="button" className="newnav" >Hyderabad</button></Link>
                        </span>
                      </div>
                    </div>

                    <span data-bs-dismiss="offcanvas"><Link href="/" onClick={() => setActive("home")} className={
                      active === "home"
                        ? `${Styles.headertitles} nav-link ${Styles.activeLink} nav-item pe-lg-2`
                        : `${Styles.headertitles} nav-link nav-item pe-lg-2`
                    }>Home
                    </Link>
                    </span>

                    <li
                      className={
                        active === "about"
                          ? `${Styles.activeLink} nav-item dropdown pe-lg-2 w-100`
                          : `${Styles.cursorPointer} nav-item dropdown pe-lg-2 w-100`
                      }
                      id="about"
                      onClick={() => setActive("about")}
                    >
                      <a
                        className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer`}
                        data-bs-target="#about"
                        data-bs-toggle="dropdown"
                        href="#"
                      >
                        About
                      </a>
                      <div
                        className={`${Styles.borderRadius} dropdown-menu`}
                        aria-labelledby="navbarDropdownMenuLink"
                        id="about"
                      >
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/about-us" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                            About Shankar IAS Academy
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/why-shankar-ias" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Why Shankar IAS Academy
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/our-infrastructure" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Infrastructure
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/our-faculty" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                            Faculty
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li
                      className={
                        active === "upsc"
                          ? `${Styles.activeLink} nav-item dropdown pe-lg-2 w-100`
                          : "nav-item dropdown pe-lg-2 w-100"
                      }
                      id="upsc"
                      onClick={() => setActive("upsc")}
                    >
                      <a
                        className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer`}
                        data-bs-toggle="dropdown"
                        href="#"
                        data-bs-target="#upsc"
                      >
                        UPSC{" "}
                      </a>
                      <div
                        className={`${Styles.borderRadius} dropdown-menu`}
                        aria-labelledby="navbarDropdownMenuLink"
                        id="upsc"
                      >
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/about-upsc" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                            About Civil Service
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-admission-anna-nagar" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                            UPSC - Classroom & Online
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-interview" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Interview 2023 - Free Coaching
                          </Link>
                        </span>
                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-prefit-test" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            UPSC - Prefit Test Series 2024
                          </Link>
                        </span> */}
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-prelims-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Prelims 2024 Test Series
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/upsc-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Mains 2023 Test Series
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/about-forestry" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            About Forest Service
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/forestry-admission" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                            Forestry & Geology Optionals
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/ifs-interview" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                            UPSC IFS 2023 FREE Interview Coaching
                          </Link>
                        </span>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/sia-all-india-prelims-mock-test" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                            All India Prelims Mock Test 2024
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li
                      className={
                        active === "tnpsc"
                          ? `${Styles.activeLink} nav-item dropdown pe-lg-2 w-100`
                          : "nav-item dropdown pe-lg-2 w-100 "
                      }
                      id="tnpsc"
                      onClick={() => setActive("tnpsc")}
                    >
                      <a
                        className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer`}
                        data-bs-toggle="dropdown"
                        href="#"
                        data-bs-target="#tnpsc"
                      >
                        TNPSC{" "}
                      </a>
                      <div
                        className={`${Styles.borderRadius} dropdown-menu cursorPointer`}
                        aria-labelledby="navbarDropdownMenuLink"
                        id="tnpsc"
                      >

                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-admissions" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                            Gr I, II & IIA Prelims
                          </Link>
                        </span> */}

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-admissions" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                            TNPSC Gr I / II Prelims Coaching
                          </Link>
                        </span>

                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-i-prelims-test" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Group I Prelims 2023 Test Series
                          </Link>
                        </span> */}

                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Group II/II-A Mains Paper 1
                          </Link>
                        </span> */}

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-general-enrichment-programme" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            TNPSC-GMAT Enrichment Programme<br />Group II & IV 2024
                          </Link>
                        </span>

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-iv-test-batch" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            TNPSC Group IV Test Series 2024
                          </Link>
                        </span>

                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&ifkv=ASKXGp1YLKv7QkngPqJtkL_P79ajBkZdNb8EwaW0mOhjWzEruq_teiol2FUg7p9ydl78aZ6xee2byQ&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1728887634%3A1704693503982050&theme=glif" route="_blank" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            TNPSC Group II (OT) Free Mock Interview 2024
                          </Link>
                        </span> */}

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-iv-workshop" route="_blank" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            TNPSC Group IV Class-cum-Workshop 2023
                          </Link>
                        </span>

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-i-prelims-test-series" route="_blank" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                          TNPSC Group I & II Prelims Test Series
                          </Link>
                        </span>

                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-ii-main-2022-test-series-paper-i" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Group II Mains 2022 Test Series Paper 1
                          </Link>
                        </span>

                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-II-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Group II/II-A Mains 2022 Test Series Paper 2
                          </Link>
                        </span> */}


                        {/* <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-i-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            TNPSC Group I GS Mains Test Series 2023
                          </Link>
                        </span>


                        <span data-bs-dismiss="offcanvas">
                          <Link href="/tnpsc-group-i-mains-crash-course" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                            TNPSC – Group I Mains Crash Course
                          </Link>
                        </span> */}
                      </div>
                    </li>
                    <li
                      className={
                        active === "banking"
                          ? `${Styles.activeLink} nav-item pe-lg-2`
                          : "nav-item pe-lg-2"
                      }
                      id="banking"
                      onClick={() => setActive("banking")}
                    >
                      <a href="https://www.shankarbankingacademy.com/" target={"_blank"} rel="noreferrer"
                        className={`${Styles.headertitles} nav-link cursorPointer`}
                      >
                        Banking
                      </a>
                    </li>
                    <li
                      className={
                        active === "learnCorner"
                          ? `${Styles.activeLink} nav-item dropdown pe-lg-2 w-100`
                          : "nav-item dropdown pe-lg-2 w-100"
                      }
                      id="learnCorner"
                      onClick={() => setActive("learnCorner")}
                    >
                      <a
                        className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer`}
                        data-bs-toggle="dropdown"
                        href="#"
                        data-bs-target="#learningCorner"
                      >
                        Learning Corner
                      </a>
                      <div
                        className={`${Styles.borderRadius} dropdown-menu`}
                        aria-labelledby="navbarDropdownMenuLink"
                        id="learningCorner"
                      >
                        <a href="https://www.shankariasacademy.com/postalmaterial/" rel="noreferrer"
                          className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}
                        >
                          Study Materials
                        </a>
                        <a href="/current-affairs" target={"_blank"} rel="noreferrer"
                          className={`${Styles.headerdropdown} dropdown-item cursorPointer`}
                        >
                          Current Affairs
                        </a>
                        <a href="https://www.iasparliament.com/UPSC-prelims-free-mock-test" target={"_blank"} rel="noreferrer"
                          className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}
                        >
                          Daily Quiz
                        </a>
                      </div>
                    </li>
                    <li
                      className={
                        active === "result"
                          ? `${Styles.activeLink} nav-item`
                          : "nav-item"
                      }
                      id="result"
                      onClick={() => setActive("result")}
                    >
                      <span data-bs-dismiss="offcanvas">
                        <Link href="/our-achievers" className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}>
                          Achievements
                        </Link>
                      </span>
                    </li>
                    <li
                      className={
                        active === "contact"
                          ? `${Styles.activeLink} nav-item`
                          : "nav-item"
                      }
                      id="contact"
                      onClick={() => setActive("contact")}
                    >
                      <span data-bs-dismiss="offcanvas">
                        <Link href="/contact-us" className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}>
                          Contact
                        </Link>
                      </span>
                    </li>
                    <li
                      className={
                        active === "career"
                          ? `${Styles.activeLink} nav-item`
                          : "nav-item"
                      }
                      id="career"
                      onClick={() => setActive("career")}
                    >
                      <span data-bs-dismiss="offcanvas">
                        <Link href="/careers" className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}>
                          Careers
                        </Link>
                      </span>
                    </li>
                    <li
                      className={
                        active === "blog"
                          ? `${Styles.activeLink} nav-item`
                          : "nav-item"
                      }
                      id="blog"
                      onClick={() => setActive("blog")}
                    >
                      <a href="/blog" rel="noreferrer"
                        className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}
                        aria-current="page"
                      >
                        Blog
                      </a>
                    </li>
                    <li
                      className={
                        active === "login"
                          ? `${Styles.activeLink} nav-item`
                          : "nav-item"
                      }
                      id="login"
                      onClick={() => setActive("login")}
                    >
                      <a href="https://admissions.shankariasacademy.com/online/" target={"_blank"} rel="noreferrer"
                        className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}
                        aria-current="page"
                      >
                        Login
                      </a>
                    </li>
                    <div className=" pt-2">
                      <p>
                        <span data-bs-dismiss="offcanvas">
                          <Link href="/admission-consultation" className={`${Styles.headerbutton} p-2`}>
                            Admission Consultation
                          </Link>
                        </span>
                      </p>
                      <p className="mb-2">
                        <Image src={homemail} className="img-fluid" alt="mail icon" title="Mail Link" />
                        <a href="mailto:enquiry@shankarias.in" className={`${Styles.toptopheaderlinks3} ${Styles.toptopheader2} ps-1 pe-2`}>enquiry@shankarias.in</a>
                      </p>
                      <p className="mb-1">
                        <Image src={homephone} className="img-fluid" alt="phone icon" title="Phone Link" />
                        <a href="tel:044-66024500" className={`${Styles.toptopheaderlinks3} ${Styles.toptopheader2} ps-1 pe-2`}>UPSC: 7667766266 &nbsp;|&nbsp; 044-66024500</a>
                      </p>
                      <p className="mb-2">
                        <a href="tel:044-43533445" className={`${Styles.toptopheaderlinks3} ${Styles.toptopheader2} ps-3 pe-2`}>TNPSC: 044-43533445 &nbsp;|&nbsp; 044-45543082</a>
                      </p>
                      <Link href="/upsc-admission-anna-nagar" className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} mb-2 ps-3 pe-2`} >
                        <span className={active === "upsc" ? `${Styles.activeLink} nav-item` : "nav-item"} data-bs-dismiss="offcanvas" aria-current="page" id="upsc" onClick={() => setActive("upsc")}>Domestic</span>
                      </Link>
                      <Link href="/upsc-admission-nri" className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} ps-3 pe-2`} >
                        <span className={active === "upsc" ? `${Styles.activeLink} nav-item` : "nav-item"} data-bs-dismiss="offcanvas" aria-current="page" id="upsc" onClick={() => setActive("upsc")}>NRI</span>
                      </Link>
                    </div>
                    {/* Announcement section */}
                    {/* <div className="container pt-2"> 
                        <div className="row">
                          <div className="col">
                            <span className={`${Styles.announcecont3}`}>WHAT'S NEW</span>
                            <div className="pt-2"><button type="button" onClick={() => setModalShow(true)} className={`${Styles.redbtn}`}>UPSC</button></div>
                            <div className="pt-2"><button type="button" onClick={() => setModalShow1(true)} className={`${Styles.yellowbtn}`}>TNPSC</button></div>
                            <div className="pt-2"><button type="button" className={`${Styles.bluebtn}`}>Banking</button></div>
                          </div>
                        </div>
                      </div> */}
<div>
        <Modal
          size="lg"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Body className="p-md-5">
            <div className="text-end">
              <Image onClick={() => setModalShow(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
            </div>
            <p className={`${Styles.announcecont1}`}>What's New</p>
            {/* <div className="row">
              <div className="col pb-3">
                <Image src={UPSC} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>UPSC</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6  col-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr style={{opacity:"1"}}></hr>
           
            {/* <div className="row">
              <div className="col pb-3">
                <Image src={TNPSC} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>TNPSC</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement1.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements1.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          <hr style={{opacity:"1"}}></hr>
          {/* <p className={`${Styles.announcecont1}`}>What's New</p>
            <div className="row">
              <div className="col pb-3">
                <Image src={Banking} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>Banking</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement2.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements2.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </div>

                    {/* <div>
                      <Modal
                        size="lg"
                        centered
                        show={modalShow1}
                        onHide={() => setModalShow1(false)}
                      >
                        <Modal.Body className="p-md-5">
                          <div className="text-end">
                            <Image onClick={() => setModalShow1(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
                          </div>
                          <p className={`${Styles.announcecont1}`}>What's New</p>
                          <div className="row">
                            <div className="col pb-3">
                              <Image src={TNPSC} className="img-fluid" alt="new_notifications" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                                {announcement1.map((item, index) => (
                                  <li className={`${Styles.ulliclass1}`} key={index}>
                                    <div className="d-inline">
                                      <p className={`${Styles.ullispan1} d-inline`}>
                                        <span data-bs-dismiss="offcanvas">
                                          <a href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</a>
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div> */}


                    {/* <div>
                      <Modal
                        size="lg"
                        centered
                        show={modalShow2}
                        onHide={() => setModalShow2(false)}
                      >
                        <Modal.Body className="p-md-5">
                          <div className="text-end">
                            <Image onClick={() => setModalShow2(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
                          </div>
                          <p className={`${Styles.announcecont1}`}>What's New</p>
                          <div className="row">
                            <div className="col pb-3">
                              <Image src={Banking} className="img-fluid" alt="new_notifications" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                                {announcement2.map((item, index) => (
                                  <li className={`${Styles.ulliclass1}`} key={index}>
                                    <div className="d-inline">
                                      <p className={`${Styles.ullispan1} d-inline`}>
                                        <span data-bs-dismiss="offcanvas">
                                          <a href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</a>
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div> */}

                  </ul>
                </div>
              </div>
            </div>

          </div>
        </nav>
      </div>
    </>
  );
};

export default Nav;
