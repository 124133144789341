import Styles from "./Navbar.module.css";
import Link from "next/link";
import { useRouter } from "next/router";
import Navdesk from "./Nav Desk";
import { useState, useEffect } from 'react';

const Nav = (...props) => {

  const router = useRouter();
  
  const [active, setActive] = useState(props.title);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset >= sticky);
    };

    const sticky = document.getElementById("navbar").offsetTop;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <Navdesk className="header" />
      <div id="navbar" className={isSticky ? "sticky" : ""}>
      <div className="container-fluid p-0 d-none d-lg-block">
        <nav className={`${Styles.navstyle} navbar navbar-expand-lg py-0`} style={{ background: "#F4F6F8 0% 0% no-repeat padding-box" }}>
          <div className="container">

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="custom-border1">
                  <Link href="/" onClick={() => setActive("home")} className={
                    active === "home"
                      ? `${Styles.headertitles} nav-link cursorPointer ${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2`
                      : `${Styles.headertitles} nav-link cursorPointer nav-item pe-2 mx-xl-3 mx-lg-2`
                  }>
                    <span className="pe-xl-4 pe-lg-3">Home</span>
                  </Link>
                </li>
                <li
                  className={
                    active === "about"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="about"
                  onClick={() => setActive("about")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#about"
                    href="#"
                  >
                    <span className="pe-2">About{" "}</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu cursorPointer`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="about"
                  >
                    <Link href="/about-us" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                      About Shankar IAS Academy
                    </Link>
                    <Link href="/why-shankar-ias" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Why Shankar IAS Academy
                    </Link>
                    <Link href="/our-infrastructure" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Infrastructure
                    </Link>
                    <Link href="/our-faculty" className={`${Styles.headerdropdown} dropdown-item  pb-2 cursorPointer`}>
                      Faculty
                    </Link>
                  </div>
                </li>
                <li
                  className={
                    active === "upsc"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="upsc"
                  onClick={() => setActive("upsc")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#upsc"
                    href="#"
                  >
                    <span className="pe-2">UPSC{" "}</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="upsc"
                  >
                    <Link href="/about-upsc" className={`${Styles.headerdropdown} dropdown-item headerdropdown pt-2 cursorPointer`}>
                      About Civil Service
                    </Link>
                    <Link href="/upsc-admission-anna-nagar" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                      UPSC - Classroom & Online
                    </Link>
                    <Link href="/upsc-interview" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Interview 2023 - Free Coaching
                    </Link>
                    {/* <Link href="/upsc-prefit-test" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      UPSC - Prefit Test Series 2024
                    </Link> */}
                    <Link href="/upsc-prelims-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Prelims 2024 Test Series
                    </Link>
                    <Link href="/upsc-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Mains 2023 Test Series
                    </Link>
                    <Link href="/about-forestry" className={`${Styles.headerdropdown} dropdown-item headerdropdown cursorPointer`}>
                      About Forest Service
                    </Link>
                    <Link href="/forestry-admission" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                      Forestry & Geology Optionals
                    </Link>
                    <Link href="/ifs-interview" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                      UPSC IFS 2023 FREE Interview Coaching
                    </Link>
                    <Link href="/sia-all-india-prelims-mock-test" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                      All India Prelims Mock Test 2024
                    </Link>
                  </div>
                </li>
                <li
                  className={
                    active === "tnpsc"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="tnpsc"
                  onClick={() => setActive("tnpsc")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#tnpsc"
                    href="#"
                  >
                    <span className="pe-2">TNPSC{" "}</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu cursorPointer`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="tnpsc"
                  >
                    <Link href="/tnpsc-admissions" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                      TNPSC Gr I / II Prelims Coaching
                    </Link>
                    {/* <Link href="/tnpsc-admissions" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                            Gr I, II & IIA Prelims
                      </Link> */}
                    {/* <Link href="/tnpsc-group-i-prelims-test" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Group I Prelims 2023 Test Series
                    </Link> */}
                    {/* <Link href="/tnpsc-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Group II/II-A Mains Paper 1
                    </Link> */}
                    <Link href="/tnpsc-general-enrichment-programme" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC-GMAT Enrichment Programme Group II & IV 2024
                    </Link>
                    <Link href="/tnpsc-group-iv-test-batch" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC Group IV Test Series 2024
                    </Link>
                    {/* <Link href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdFBF5vwf2qBXp5XvyN-jaEklei5bPBXha_gB6hnIc_eHsBeg%2Fviewform&ifkv=ASKXGp1YLKv7QkngPqJtkL_P79ajBkZdNb8EwaW0mOhjWzEruq_teiol2FUg7p9ydl78aZ6xee2byQ&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1728887634%3A1704693503982050&theme=glif" route="_blank" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC Group II (OT) Free Mock Interview 2024
                    </Link> */}
                    <Link href="/tnpsc-group-iv-workshop" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC Group IV Class-cum-Workshop 2023
                    </Link>
                    <Link href="/tnpsc-group-i-prelims-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC Group I & II Prelims Test Series
                    </Link>
                    {/* <Link href="/tnpsc-group-ii-main-2022-test-series-paper-i" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Group II Mains 2022 Test Series Paper 1
                    </Link>
                    <Link href="/tnpsc-group-II-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Group II/II-A Mains 2022 Test Series Paper 2
                    </Link> */}
                    {/* <Link href="/tnpsc-group-i-mains-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      TNPSC Group I GS Mains Test Series 2023
                    </Link>
                    <Link href="/tnpsc-group-i-mains-crash-course" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                      TNPSC – Group I Mains Crash Course
                    </Link> */}

                  </div>
                </li>
                <li
                  className={
                    active === "banking"
                      ? `${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2 custom-border1`
                      : "nav-item pe-2 mx-xl-3 mx-lg-2 custom-border1"
                  }
                  id="banking"
                  onClick={() => setActive("banking")}
                >
                  <a href="https://www.shankarbankingacademy.com/" target={"_blank"} rel="noreferrer"
                    className={`${Styles.headertitles} nav-link cursorPointer`}
                  >
                    <span className="pe-xl-4 pe-lg-3">Banking</span>
                  </a>
                </li>
                <li
                  className={
                    active === "learnCorner"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="learnCorner"
                  onClick={() => setActive("learnCorner")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#learningCorner"
                    href="#"
                  >
                    <span className="pe-2">Learning Corner</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu borderRadius`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="learningCorner"
                  >
                    <a href="https://www.shankariasacademy.com/postalmaterial/" rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}
                    >
                      Study Materials
                    </a>
                    <a href="/current-affairs" target={"_blank"} rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item cursorPointer`}
                    >
                      Current Affairs
                    </a>
                    <a href="https://www.iasparliament.com/UPSC-prelims-free-mock-test" target={"_blank"} rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}
                    >
                      Daily Quiz
                    </a>
                  </div>
                </li>
                <li
                  className={
                    active === "result"
                      ? `${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2`
                      : "nav-item pe-2 mx-xl-3 mx-lg-2"
                  }
                  id="result"
                  onClick={() => setActive("result")}
                >
                  <Link href="/our-achievers" className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}>
                    Achievements
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      </div>

    </>
  );
};

export default Nav;
